import { useEffect, useLayoutEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import PageNotAccessible from '../PageNotAccessible';

import { AppContext } from "../../AppContext";

import ReportPath from './report/ReportPath'
import ReportToolbar from './report/ReportToolbar';
import NewReportToolbar from './report/NewReportToolbar';

import PowerBiService, { MyWorkspace } from '../../services/PowerBiService'
import { PowerBiDataset, PowerBiReport, PowerBiWorkspace, PowerBiDashboard } from '../../models/PowerBiModels';
import { Box } from '@mui/material';
import React from 'react';

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";

// ensure Power BI JavaScript API has loaded
require('powerbi-models');
require('powerbi-client');



export type ViewMode = "FitToPage" | "FitToWidth" | "ActualSize";

const Dashboard = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const embedContainer = useRef(null);
    const { path } = useParams();
    const { appContextData, setCurrentWorkspaceId, refreshDashboardList } = useContext(AppContext)

    const [embeddedReport, setEmbeddedReport] = useState<powerbi.Dashboard | null>(null);
    const [embeddedNewReport, setEmbeddedNewReport] = useState<powerbi.Embed | null>(null);

    const [embedType, setEmbedType] = useState<"Dashboard" | "Dashboard" | null>(null);
    const [reportType, setReportType] = useState<"PowerBiDashboard" | "Dashboard" | null>(null);

    const [editMode, setEditMode] = useState(false);
    const [showNavigation, setShowNavigation] = useState(true);
    const [showFiltersPane, setShowFiltersPane] = useState(true);
    const [showBookmarksPane, setShowBookmarksPane] = useState(false);
    const [viewMode, setViewMode] = useState<ViewMode>("FitToPage");

    const [reportPath, setReportPath] = useState("");

    const embedExistingDashboard = async (WorkspaceId: string, DashboardId: string) => {

        const params = new URLSearchParams(window.location.search);
        const openInEditMode = false;
        setEditMode(openInEditMode);

        const defaultShowNavigation: boolean = false;
        setShowNavigation(defaultShowNavigation);
        const defaultShowFilterPane: boolean = false;
        setShowFiltersPane(defaultShowFilterPane);
        const defaultShowBookmarksPane: boolean = false;
        setShowBookmarksPane(defaultShowBookmarksPane);
        const defaultViewMode: ViewMode = 'FitToPage';
        setViewMode(defaultViewMode);

        let dashboard: PowerBiDashboard = await PowerBiService.GetDashboard(WorkspaceId, DashboardId);

        if (appContextData.currentWorkspaceName && dashboard.displayName) {
            setReportPath(appContextData.currentWorkspaceName + " > " + dashboard.displayName);
        }
        else {
            setReportPath("...");
        }

        // if (report.reportType === "PaginatedReport") {
        //     setReportType("PaginatedReport")
        //     embedPaginatedReport(WorkspaceId, report);
        //     return;
        // }

        setEmbedType("Dashboard")
        setReportType("Dashboard")

        let accessToken: string = await PowerBiService.GetAccessToken();

        var config: powerbi.IEmbedConfiguration = {
            type: 'dashboard',
            id: dashboard.id,
            embedUrl: dashboard.embedUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Aad,
            viewMode:  models.ViewMode.View,
            permissions: models.Permissions.All,
            pageView: 'fitToWidth',
            settings: {
                
                    layoutType: models.LayoutType.Custom,
                    customLayout: { displayOption: models.DisplayOption.FitToPage }
            
    
            }
        };

        console.log("config dashboard", config);

        // Embed the report and display it within the div container                
        var embedDashboard: powerbi.Dashboard = (window.powerbi.embed(embedContainer.current, config) as powerbi.Dashboard);

        // embedDashboard.on("filtersApplied", (args) => { embedDashboard.savePersistentFilters(); });
        // embedDashboard.on("dataSelected", (args) => { embedDashboard.savePersistentFilters(); });
        // embedDashboard.on("visualClicked", (args) => { embedDashboard.savePersistentFilters(); });
        // embedDashboard.on("selectionChanged", (args) => { embedDashboard.savePersistentFilters(); });
  
        setEmbeddedReport(embedDashboard);
        console.log(embedDashboard);

        // embedDashboard.on("saved", (event: any) => {

        //     console.log("embeddedReport.on", event);

        //     if (event.detail.saveAs) {
        //         refreshDashboardList();
        //         var newReportId = event.detail.reportObjectId;
        //         var newReportName = event.detail.reportName;
        //         navigate("/reports/" + WorkspaceId + "@" + newReportId + "/?edit=true&newReport=true");
        //     }

        // });

    };

    // const embedNewReport = async (WorkspaceId: string, DatasetId: string) => {

    //     let dataset: PowerBiDataset = await PowerBiService.GetDataset(WorkspaceId, DatasetId);
    //     let accessToken: string = await PowerBiService.GetAccessToken();

    //     var config: powerbi.IEmbedConfiguration = {
    //         type: 'report',
    //         datasetId: dataset.id,
    //         embedUrl: dataset.createReportEmbedURL,
    //         accessToken: accessToken,
    //         tokenType: models.TokenType.Aad,
    //         settings: {
    //             bars: {
    //                 actionBar: { visible: false }
    //             },
    //             panes: {
    //                 filters: { expanded: false, visible: true }
    //             }
    //         }
    //     };

    //     // Embed the report and display it within the div container.
    //     var embeddedNewReport: powerbi.Embed = window.powerbi.createReport(embedContainer.current, config);
    //     setEmbeddedNewReport(embeddedNewReport);

    //     embeddedNewReport.on("saved", (event: any) => {

    //         console.log("Saved");
    //         console.log(event.detail);

    //         // get ID and name of new report
    //         refreshReportList();
    //         var newReportId = event.detail.reportObjectId;
    //         var newReportName = event.detail.reportName;
    //         navigate("/reports/" + WorkspaceId + "@" + newReportId + "/?edit=true&newReport=true")
    //     });

    // };

    const embedDashboard = async (WorkspaceId: string, Dashboard: PowerBiDashboard) => {

        let accessToken: string = await PowerBiService.GetAccessToken();

        var config: powerbi.IEmbedConfiguration = {
            type: 'dashboard',
            id: Dashboard.id,
            embedUrl: Dashboard.embedUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Aad,
            pageView: 'fitToWidth',
            //pageView: models.DisplayOption.FitToPage,
            
            settings: {

                layoutType: models.LayoutType.Custom,
                customLayout: { displayOption: models.DisplayOption.FitToPage }
            }
        };

        console.log("config dashboard", config);

        // Embed the report and display it within the div container                
        var embedDashboard: powerbi.Dashboard = (window.powerbi.embed(embedContainer.current, config) as powerbi.Dashboard);

    };

    // const embedPaginatedReport = async (WorkspaceId: string, Report: PowerBiReport) => {

    //     let accessToken: string = await PowerBiService.GetAccessToken();

    //     var config: powerbi.IEmbedConfiguration = {
    //         type: 'report',
    //         id: Report.id,
    //         embedUrl: Report.embedUrl,
    //         accessToken: accessToken,
    //         tokenType: models.TokenType.Aad,
    //         settings: {}
    //     };

    //     console.log("config", config);

    //     // Embed the report and display it within the div container                
    //     var embeddedReport: powerbi.Report = (window.powerbi.embed(embedContainer.current, config) as powerbi.Report);

    // };

    

    // set height of embed container relative to height of window
    const setReportContainerHeight = () => {
        var reportContainer: HTMLElement = embedContainer.current;
        var reportContainerTopPosition = reportType === "Dashboard" ? 88 : 122;
        reportContainer.style.height = (window.innerHeight - reportContainerTopPosition - 10) + "px";
    };

    useLayoutEffect(() => {
        if (isAuthenticated) {
            setReportContainerHeight();
            window.addEventListener("resize", setReportContainerHeight);
        }
    }, [isAuthenticated, reportType]);

    // call Power BI REST API and embed report
    useEffect(() => {

        const test = async () => {

            window.powerbi.reset(embedContainer.current);

            // handle URL for embedding existing report
            console.log("this is what we are reseting");
            console.log(path);
            if (path.includes("@")) {
                setEmbedType("Dashboard");
                let parts = path.split("@");
                let workspaceId = parts[0];
                let reportId = parts[1];
                if (workspaceId !== appContextData.currentWorkspaceId) {
                    setCurrentWorkspaceId(workspaceId);
                }
                embedExistingDashboard(workspaceId, reportId);
                return;
            }

      
        }

        if (isAuthenticated) { test(); }

    }, [path, appContextData]);

    if (!isAuthenticated) {
        return <PageNotAccessible />;
    }
    else {
        return (
            <Box sx={{ display: "grid", gridAutoFlow: "row", width: 1 }}>

                <ReportPath reportPath={reportPath} />

                {/* {embedType === "Dashboard" && reportType === "PowerBiDashboard" &&
                    <ReportToolbar report={embeddedReport}
                        editMode={editMode} setEditMode={setEditMode} showNavigation={showNavigation} setShowNavigation={setShowNavigation}
                        showFiltersPane={showFiltersPane} setShowFiltersPane={setShowFiltersPane} viewMode={viewMode} setViewMode={setViewMode}
                        showBookmarksPane={showBookmarksPane} setShowBookmarksPane={setShowBookmarksPane} />} */}
{/* 
                {embedType === "NewReport" &&
                    <NewReportToolbar report={embeddedNewReport}
                        showFiltersPane={showFiltersPane} setShowFiltersPane={setShowFiltersPane} viewMode={viewMode} setViewMode={setViewMode}
                        showBookmarksPane={showBookmarksPane} setShowBookmarksPane={setShowBookmarksPane} />} */}


                <Box ref={embedContainer} sx={{ width: "100%" }} />

            </Box>
        );
    }
};

export default Dashboard;