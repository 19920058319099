import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../../AppContext";
import { tokens } from "../../theme";



import { Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider,Paper, Grid, TableContainer, Table, TableBody, TableRow, TableCell,useTheme } from '@mui/material';
import { Avatar, Box, ListItemAvatar } from '@mui/material';
import { Assessment, Psychology, Schema, WorkspacePremiumTwoTone } from '@mui/icons-material';

const Workspace = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { appContextData, setCurrentWorkspaceId } = useContext(AppContext);

    useEffect(() => {
        if (id !== appContextData.currentWorkspaceId) {
            setCurrentWorkspaceId(id);
        }
    });

    return (
        <Container maxWidth={false}>
       <h2>{appContextData.currentWorkspaceName}</h2>
          
     {/* <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {[0, 1, 2, 3].map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        return (
          <ListItem
            key={value}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(value)}
                checked={checked.indexOf(value) !== -1}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${value + 1}`}
                  src={`/static/images/avatar/${value + 1}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List> */}
            <Divider sx={{ my: 3 }} />
            <Container maxWidth="xl" sx={{marginTop:5}} >
                <Grid container >
                    <Grid item xs={6}  sx={{ padding: "4px" }} >
                        <Box sx={{ fontSize: "1.25rem", color: "white", backgroundColor: colors.primary[500], padding: "4px", paddingLeft: "12px", }} >
                            Reports
                        </Box>
                        <List  sx={{ bgcolor: 'background.paper' ,border: "1px solid #888888" }}>
                            {appContextData.reports &&
                                appContextData.reports.map((report) => (
                                    <ListItem key={report.id} divider dense >
                                        <ListItemButton onClick={() => { navigate("/reports/" + id + "@" + report.id); }}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Assessment />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={report.name} />
                                        </ListItemButton>
                                    </ListItem>

                                ))}
                        </List>
                    </Grid>
                    <Grid item xs={6} sx={{ padding: "4px" }}>
                        <Box sx={{ fontSize: "1.25rem", color: "white", backgroundColor: colors.primary[500], padding: "4px", paddingLeft: "12px" }} >
                            Dashboards
                        </Box>
                        <List sx={{ border: "1px solid #888888" }}>
                            {appContextData.dashboards &&
                                appContextData.dashboards.map((dashboards) => (
                                    <ListItem key={dashboards.id} divider dense >
                                         <ListItemButton onClick={() => { navigate("/dashboards/" + id + "@" + dashboards.id); }}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Schema />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={dashboards.displayName} secondary="" />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                        </List>
                    </Grid>
                </Grid>
            </Container>
           

        </Container>)
};

export default Workspace;