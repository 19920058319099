import { Box, Typography, SxProps, useTheme } from '@mui/material';
import { tokens } from "../../../theme";

import { color } from "@mui/system";
interface ReportPathProps {
    reportPath: string;
}

const ReportPath = ({ reportPath }: ReportPathProps) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const reportPathProperties: SxProps = {marginTop:1, pl:2, py:1, fontSize: "20px",bgcolor: colors.primary[700] , color: 'white', minHeight:"42px" };

    return (
        <Box sx={{ marginTop:10, width: 1, backgroundColor: colors.primary[700], p: 0, m: 0 }} >        
                <Typography variant='h2' sx={reportPathProperties} >
                    {reportPath}
                </Typography>        
        </Box>
    )
}

export default ReportPath